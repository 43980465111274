import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const Flash = makeShortcode("Flash");
const Card = makeShortcode("Card");
const Video = makeShortcode("Video");
const AudioPlayer = makeShortcode("AudioPlayer");
const List = makeShortcode("List");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-radically",
      "style": {
        "position": "relative"
      }
    }}>{`➕ Radically`}<a parentName="h1" {...{
        "href": "#-radically",
        "aria-label": " radically permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`Before being too radical, we must reflect on where we've been. Understanding
the `}<a parentName="p" {...{
        "href": "/learn/module-0/play-of-pattern/"
      }}>{`play of complementary opposites`}</a>{` allows us to see
that `}<a parentName="p" {...{
        "href": "/learn/module-0/trust/"
      }}>{`trust`}</a>{`less systems require us to define and encode what it means
to cheat. This creates new means for `}<a parentName="p" {...{
        "href": "/learn/module-1/meaning/"
      }}>{`meaning`}</a>{` - public protocols in
the `}<a parentName="p" {...{
        "href": "/learn/module-2/money-speech/"
      }}>{`same sense as language`}</a>{` - which allow
for `}<a parentName="p" {...{
        "href": "/learn/module-1/value/"
      }}>{`valuable collaboration`}</a>{` at scales not before possible.`}</p>
    <p>{`In order to frame these kinds of `}<a parentName="p" {...{
        "href": "/learn/module-0/conversation/"
      }}>{`shared stories`}</a>{`, we need to
ask `}<a parentName="p" {...{
        "href": "/learn/module-2/better-questions/"
      }}>{`better questions`}</a>{` that go to the heart of
our `}<a parentName="p" {...{
        "href": "/learn/module-3/intention/"
      }}>{`intentions`}</a>{` and stem from `}<strong parentName="p">{`honesty`}</strong>{` about,
and `}<a parentName="p" {...{
        "href": "/learn/module-3/humility/"
      }}><strong parentName="a">{`humility`}</strong></a>{` arising from, our limits as human beings.
This `}<a parentName="p" {...{
        "href": "/learn/module-0/purpose/"
      }}>{`inner orientation`}</a>{`, combined with technologies that encourage
interpersonal trust between individuals, can be used to create emergent, responsive,
communal structures better suited to coping with the `}<a parentName="p" {...{
        "href": "/learn/module-2/engineering/"
      }}>{`complexity`}</a>{`
of the `}<a parentName="p" {...{
        "href": "/learn/module-3/time/"
      }}>{`modern world`}</a>{` than our current institutions.`}</p>
    <h2 {...{
      "id": "optimal-gathering",
      "style": {
        "position": "relative"
      }
    }}>{`Optimal Gathering`}<a parentName="h2" {...{
        "href": "#optimal-gathering",
        "aria-label": "optimal gathering permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`However, we can't wave our hands, say "emergent communal structure", and leave it at that. We
need to use modern mathematical tools to model `}<strong parentName="p">{`optimal collective decision making`}</strong>{`. `}</p>
    <p>{`With reference to Ivan Illich's `}<em parentName="p">{`Tools for Conviviality`}</em>{`, `}<a parentName="p" {...{
        "href": "https://www.henryzoo.com/limits"
      }}>{`Henry Zhu`}</a>{` (a Kernel Fellow) succinctly describes what we ought to aim for:`}</p>
    <blockquote>
      <p parentName="blockquote">{`"tools to work with rather than tools that 'work' for us." These "convivial" tools nurture each person's call to help themselves and their neighbor.`}</p>
    </blockquote>
    <p>{`This is how healthy communities actually emerge: open tools which encourage collaboration without coercion; and a plurality of protocols in which malicious behaviour is transparently defined and provably more expensive than its effects.`}</p>
    <p>{`One such tool is the crowdfund matching mechanism of `}<Link to="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3243656" mdxType="Link">{`Liberal Radicalism`}</Link>{`. Extending ideas from the concept of ‘quadratic voting’, the goal of the LR mechanism is "to create a system that is as flexible and responsive as the market, but avoids free-rider problems".`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: Liberal radicalism is really a collection of mathematical tools to model what?`}</p>
        <p>{`Optimal collective decision making.`}</p>
      </Card>
    </Flash>
    <p>{`Here's the key idea: if individuals contribute to the public goods they each use `}<strong parentName="p">{`and`}</strong>{` the funding principle underlying the market is designed to be nonlinear, then we can make sure that "small contributions are heavily subsidized (as these are the most likely to be distorted by free-riding incentives) while large ones are least subsidized, as these are more likely private goods." Here's a more graphic illustration:`}</p>
    <Video src="https://www.youtube-nocookie.com/embed/hEHv-dE4xl8?start=274" mdxType="Video" />
    <h2 {...{
      "id": "capitalizing",
      "style": {
        "position": "relative"
      }
    }}>{`Capitalizing`}<a parentName="h2" {...{
        "href": "#capitalizing",
        "aria-label": "capitalizing permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Classical capitalism deals poorly with public goods problems because each individual, if she
acts selfishly, only accounts for her own benefits and not the benefits to all others. We can do
better now that we have a world computer which allows us to agree always on the history and
validity of our transactions, and which is open to anyone anywhere the internet is.`}</p>
    <p>{`Making optimal decisions about public goods and how to fund them is hard,
though: `}<Link to="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3243656" mdxType="Link">{`the math`}</Link>{`
is as complicated as elliptic curve cryptography. Happily, all you need to know here is that we
want to model citizens' different preferences as well as their ability to make negative contributions, and
we don't want to assume prior centralized knowledge about what good to fund.`}</p>
    <p>{`The maths behind LR proves that ensuring "`}<strong parentName="p">{`the amount received by `}{`[any]`}{` project is (proportional to)
the square of the sum of the square roots of contributions`}</strong>{`" is a near-optimal means of
making large-scale economic decisions about public goods. Such a design also means that "holding
fixed the amount of the contribution, the funding received grows as the square of the community
size" which kicks capitalism's ass, especially when it comes to funding goods on the margin.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: To make optimal collective decisions, the funding principle underlying the market must be nonlinear. This means that it subsidizes small or large contributions?`}</p>
        <p>{`Small `}</p>
        <p>{`Which incentivises many such contributions from "the edge" of a network, so the funding received grows as the square of the community size.`}</p>
      </Card>
    </Flash>
    <h2 {...{
      "id": "free-radicals",
      "style": {
        "position": "relative"
      }
    }}>{`Free Radicals`}<a parentName="h2" {...{
        "href": "#free-radicals",
        "aria-label": "free radicals permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`LR does not prejudge the optimal size of communities, but instead "offers a mechanism that creates
truly neutral incentives among social organization of different sizes". It also has the property
that it "reverts to a standard private good in the case that a single citizen attempts to use the
mechanism for her own enrichment."`}</p>
    <blockquote>
      <p parentName="blockquote">{`"To summarize, the mechanism provides much greater funding to many small contributions than to
a few large ones. This is not for any reason of equity or distributive justice, though there may
be good reasons from those perspectives to admire the outcome it delivers."`}</p>
    </blockquote>
    <p>{`This is a critical point: egalitarianism, or equitable outcome, is a great success metric: it is
not a good design goal (precisely because there are so many ways to measure outcomes). LR is designed to be `}<a parentName="p" {...{
        "href": "https://nakamoto.com/credible-neutrality/"
      }}>{`credibly neutral`}</a>{` in the same way that Bitcoin makes no assumptions about equality: it just implements a system for "peer-to-peer electronic
payments". The rest is on us, and how we model the most efficient means of using the technology.`}</p>
    <p>{`To sum up, Liberal Radicalism:`}</p>
    <blockquote>
      <p parentName="blockquote">{`"seeks to achieve liberal ends in a fundamentally social world. In this sense it is also
'radical' in the original meaning: it gets to the roots of what liberalism is about, namely an
anti-authoritarian commitment to neutrality across ways of living and valuing."`}</p>
    </blockquote>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: Is equitable outcome a good design goal or a more effective success metric? `}</p>
        <p>{`Success metric. `}</p>
      </Card>
      <Card mdxType="Card">
        <p>{`Prompt: What is a better design goal?`}</p>
        <p><a parentName="p" {...{
            "href": "https://nakamoto.com/credible-neutrality/"
          }}>{`Credible neutrality`}</a></p>
      </Card>
    </Flash>
    <h2 {...{
      "id": "listen",
      "style": {
        "position": "relative"
      }
    }}>{`Listen`}<a parentName="h2" {...{
        "href": "#listen",
        "aria-label": "listen permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <AudioPlayer src="https://kernel-audio.s3.amazonaws.com/M4E2+-+Radically.mp3" mdxType="AudioPlayer" />
    <h2 {...{
      "id": "practice-makes-perfect",
      "style": {
        "position": "relative"
      }
    }}>{`Practice Makes Perfect`}<a parentName="h2" {...{
        "href": "#practice-makes-perfect",
        "aria-label": "practice makes perfect permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <List mdxType="List">
      <Link to="https://gitcoin.co/blog/how-to-attack-and-defend-quadratic-funding/" mdxType="Link">
        <p>{`Defender's Advantage`}</p>
      </Link>
      <Link to="https://gitcoin.co/blog/token-engineering-open-science-program-a-multidisciplinary-study-of-gitcoin-grants/" mdxType="Link">
        <p>{`Multidisciplinary Approaches`}</p>
      </Link>
      <Link to="https://vitalik.eth.limo/general/2021/04/02/round9.html" mdxType="Link">
        <p>{`Grants Round 9: The Next Phase`}</p>
      </Link>
      <Link to="https://vitalik.eth.limo/general/2020/10/18/round7.html" mdxType="Link">
        <p>{`Grants Round 7 Retrospective`}</p>
      </Link>
      <Link to="https://vitalik.eth.limo/general/2020/07/22/round6.html" mdxType="Link">
        <p>{`Grants Round 6 Analysis`}</p>
      </Link>
      <Link to="https://vitalik.eth.limo/general/2020/04/30/round5.html" mdxType="Link">
        <p>{`Grants Round 5 Analysis`}</p>
      </Link>
    </List>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      